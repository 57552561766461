import React, { useEffect, useRef } from 'react';
import { arrayOf, bool, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field, { hasDataInFields } from '../../Field';
import BlockBuilder from '../../BlockBuilder';
import SectionContainer from '../SectionContainer';

import css from './SectionVideo.module.css';

const VideoPlayer = () => {
  return (
    <div className={css.playerWrapper}>
      <iframe
        src="https://assets.joodaic.com/cloudinary/embed/?public_id=Video_for_landing_page_2_xle16r&cloud_name=dstze85xl&profile=bannervideo&controls=false&source_types[]=mp4&source_types[]=webm"
        style={{
          width: '100%',
          height: '100%',
          aspectRatio: '640/360',
          display: 'block',
        }}
        allow="autoplay; fullscreen; encrypted-media; picture-in-picture"
        allowFullScreen
        playsInline
      />
    </div>
  );
};

const SectionVideo = props => {
  const {
    sectionId,
    className,
    rootClassName,
    defaultClasses,
    title,
    description,
    appearance,
    callToAction,
    blocks,
    isInsideContainer,
    options,
  } = props;

  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };

  const hasHeaderFields = hasDataInFields([title, description, callToAction], fieldOptions);
  const hasBlocks = blocks?.length > 0;

  return (
    <SectionContainer
      id={sectionId}
      rootClassName={rootClassName}
      className={css.sectionContent}
      appearance={appearance}
      options={fieldOptions}
    >
      <div className={css.videoContainer}>
        <VideoPlayer />
      </div>
      {hasHeaderFields ? (
        <header className={classNames(defaultClasses.sectionDetails, css.contentOverlay)}>
          <Field data={title} className={defaultClasses.title} options={fieldOptions} />
          <Field data={description} className={defaultClasses.description} options={fieldOptions} />
          <Field data={callToAction} className={defaultClasses.ctaButton} options={fieldOptions} />
        </header>
      ) : null}
      {hasBlocks ? (
        <div
          className={classNames(defaultClasses.blockContainer, {
            [css.noSidePaddings]: isInsideContainer,
          })}
        >
          <BlockBuilder
            blocks={blocks}
            sectionId={sectionId}
            ctaButtonClass={defaultClasses.ctaButton}
            options={options}
          />
        </div>
      ) : null}
    </SectionContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: object, pickValidProps: object }),
});

SectionVideo.defaultProps = {
  className: css.sectionContent,
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  blocks: [],
  isInsideContainer: false,
  options: null,
};

SectionVideo.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  blocks: arrayOf(object),
  isInsideContainer: bool,
  options: propTypeOption,
};

export default SectionVideo; 